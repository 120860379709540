import React from "react";

import { MainLayout } from "../layouts/Main";
import { LegalPageContent } from "../page-content/LegalPage";

export const PrivacyPolicyPage = () => {
  return (
    <MainLayout
      title="Basilium's Privacy Policy"
      description="Basilium's privacy policy spells out the details about what data is being collected, with whom it is being shared, and how it is used."
    >
      <LegalPageContent document="privacy" />
    </MainLayout>
  );
};

export default PrivacyPolicyPage;
